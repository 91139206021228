import { Directive, HostListener, inject } from '@angular/core';

// 3rd party
import { NzModalService } from 'ng-zorro-antd/modal';
import { firstValueFrom } from 'rxjs';

// Lib
import { BaseComponent } from 'uikit';

@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class FormDrawer extends BaseComponent {
  abstract get isDirty(): boolean;

  private _modal = inject(NzModalService);

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return !this.isDirty;
  }

  async onCancel(): Promise<boolean> {
    if (!this.isDirty) {
      return true;
    }

    let shouldClose = true;

    const confDialog = this._modal.confirm({
      nzTitle: 'Are you sure?',
      nzContent: 'You have unsaved changes.',
      nzClosable: false,
      nzCancelText: 'Back',
      nzOkText: 'Discard changes',
      nzOnCancel: () => {
        shouldClose = false;
      }
    });

    await firstValueFrom(confDialog.afterClose);
    return shouldClose;
  }
}
